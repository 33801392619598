<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv3">
                <div class="w-100">
                    <div class="pad16">
                        <p class="pb2 f3 b">
                            Preview page for Bulk upload
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickModalIcon"
                                    @click="modalBulkSEP = true"
                                    style="cursor: pointer; padding-bottom: 1px"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="modalBulkSEP">
                            You can upload your Sales from Excel to make your Account up-to-date. Click
                            <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                    </div>

                    <div class="flex flex-wrap gap16 mbWrapp">
                        <div class="flex flex-column gap16 w-50-l w-100 addMargin box-border pa4 mt4" style="border-radius: 8px">
                            <p><img :src="require('@/assets/images/bulkProduct.svg')" /></p>
                            <router-link :to="{ name: 'ViewBulkUpdateProductService' }"
                                ><p class="font20 b">Products</p></router-link
                            >
                            <p class="font24 blueColor b">50,000</p>
                            <div class="flex gap8">
                                <p class="flex items-center">
                                    <!-- <span
                                        ><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px"
                                    /></span> -->
                                    <span class="font-w1 pr2" style="color: #06b04d">40 </span>
                                    <span> Products</span>
                                </p>
                                <p class="flex items-center">
                                    <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                    ><span class="b pr2"> 40 </span> Service with error
                                </p>
                            </div>
                        </div>
                        <div class="flex flex-column gap16 w-50-l w-100 addMargin box-border pa4 mt4" style="border-radius: 8px">
                            <p><img :src="require('@/assets/images/bulkProduct.svg')" /></p>
                            <router-link :to="{ name: 'ViewBulkUpdateProductService' }"
                                ><p class="font20 b">Services</p></router-link
                            >
                            <p class="font24 blueColor b">50,000</p>
                            <div class="flex gap8">
                                <p class="flex items-center">
                                    <!-- <span
                                        ><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px"
                                    /></span> -->
                                    <span class="font-w1 pr2" style="color: #06b04d">40 </span>
                                    <span> Products</span>
                                </p>
                                <p class="flex items-center">
                                    <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                    ><span class="b pr2"> 40 </span> Service with error
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="box-border pa3 mt5" style="border-radius: 8px">
                        <h3>Here is what going with Business A</h3>
                        <div class="flex gap8">
                            <p class="flex items-center">
                                <!-- <span><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px" /></span> -->
                                <span class="font-w1 pr2" style="color: #06b04d">40 </span>
                                <span> Products</span>
                            </p>
                            <p class="flex items-center">
                                <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                ><span class="b pr2"> 4 </span> Service with error
                            </p>
                        </div>
                        <div class="mt4 flex" style="justify-content: end">
                            <div class="flex gap8">
                                <button class="btn2">View All Transactions</button>
                                <button class="btn-primary">Complete Import</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template></app-wrapper
    >
</template>
<script>
import { ref } from 'vue'
import AppWrapper from '@/layout/AppWrapper'
export default {
    name: 'BulkUpdateProductService',
    components: { AppWrapper },
    setup() {
        const modalBulkSEP = ref(false)

        return {
            modalBulkSEP,
        }
    },
}
</script>
<style scoped>
@media screen and (min-width: 500px) {
    .mbWrapp {
        flex-wrap: nowrap;
    }
}
</style>
